import React from 'react'
import api from 'api'

export default (streetAddress, mapAddress) => {
  const [address, setAddress] = React.useState(streetAddress)
  const [shownAddress, setShownAddress] = React.useState(streetAddress)
  // this is the address which is shown on the map
  const [map, setMap] = React.useState(mapAddress)
  const updateMap = (newAddress) => {
    if (shownAddress !== newAddress) {
      api.maps.preview(newAddress)
        .then((mapSource) => {
          setMap(mapSource)
          setShownAddress(newAddress)
        })
    }
  }
  const handleAddressChange = (e) => setAddress(e.target.value)
  const handleAddressLeave = (e) => updateMap(e.target.value)
  const handleAddressKeyDown = (e) => e.keyCode === 13 && updateMap(e.target.value)
  return {
    value: address,
    map,
    currentValue: shownAddress,
    onBlur: handleAddressLeave,
    onChange: handleAddressChange,
    onKeyDown: handleAddressKeyDown,
  }
}
