import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
// Source: https://github.com/JoshK2/react-spinners-css/blob/master/src/components/Ring
const LoadingSpinner = ({
  color, size, className, style,
}) => {
  const circles = [...Array(4)].map(() => (
    <div
      key={window.crypto.getRandomValues(new Uint32Array(8))[0]}
      style={{
        borderColor: `${color} transparent transparent transparent`,
        width: size * 0.8,
        height: size * 0.8,
        margin: size * 0.1,
        borderWidth: size * 0.1,
      }}
    />
  ))

  return (
    <div className={`lds-ring ${className}`} style={{ width: size, height: size, ...style }}>
      {circles}
    </div>
  )
}

LoadingSpinner.propTypes = {
  /** hex color */
  color: PropTypes.string,
  /** size in pixel */
  size: PropTypes.number,
  /** class name  */
  className: PropTypes.string,
  /** style object */
  style: PropTypes.objectOf(PropTypes.any),
}

LoadingSpinner.defaultProps = {
  color: '#000000',
  size: 80,
  className: '',
  style: {},
}

export default LoadingSpinner
