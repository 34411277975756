import React from 'react'
import PropTypes from 'prop-types'
import styles from './GraphLegendStyles'

const GraphLegendItem = ({
  label, color, offset, iconClass,
}) => {
  const classes = styles(offset, color)
  return (
    <div>
      <i className={`fas fa-${iconClass} ${classes.legendItem}`} />
      <span>{label}</span>
    </div>
  )
}

GraphLegendItem.defaultProps = {
  offset: 0.0,
}

GraphLegendItem.propTypes = {
  offset: PropTypes.number,
  color: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default GraphLegendItem
