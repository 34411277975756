import get from './get'
import set from './set'
import accept from './accept'
import reject from './reject'
import remove from './remove'

export default {
  get,
  set,
  accept,
  reject,
  remove,
}
