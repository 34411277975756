import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    paper: {
      padding: '10px',
      margin: '10px',
    },
  })
  const classes = useStyles()
  return classes
}
