import { useJss } from 'lq-ui'

export default (offset, color) => {
  const useStyles = useJss({
    toolbar: {
      display: 'flex',
    },
    legendItem: {
      padding: '5px',
      fontSize: `${1.5 + offset}rem`,
      verticalAlign: 'middle',
      color,
      fontWeight: 'bold',
      fontFamily: '\'Font Awesome 5 Pro\'',
    },
  })
  const classes = useStyles()
  return classes
}
