const initialState = []


export default (state = initialState, action) => {
  switch (action.type) {
    case 'PROPOSALS_LOAD_SUCCESS':
      return action.payload
    case 'ACCEPT_PROPOSAL':
      return action.payload
    case 'REJECT_PROPOSAL':
      return action.payload
    case 'REMOVE_PROPOSAL':
      return action.payload
    default:
      return state
  }
}
