import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    deleteButton: {
      minWidth: '56px',
      minHeight: '56px',
      backgroundColor: '#B00020',
      color: '#fff',
      alignSelf: 'flex-start',
      '&:hover': {
        backgroundColor: '#B00020',
      },
    },
    modal: {
      backgroundColor: '#fff',
      maxWidth: '600px',
      margin: '10% auto 0',
      borderRadius: '5px',
    },
    modalHeader: {
      padding: '1rem',
    },
    modalContent: {
      padding: '1rem',
    },
    modalFooter: {
      borderTop: '2px solid #ccc',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1rem',
    },
    confirmButton: {
      backgroundColor: '#B00020',
      color: '#fff',
      marginRight: '1rem',
      '&:hover': {
        backgroundColor: '#B00020',
      },
    },
    cancelButton: {
      backgroundColor: '#757575',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#757575',
      },
    },
  })
  return useStyles()
}
