import { createSelector } from 'reselect'
import { nodeSelector } from './selectedGraph'
import { computeGraph } from './graph'

export const providersSelector = createSelector(
  (state) => state.graph.providers,
  (providers) => providers,
)

export const nodeProvidersSelector = createSelector(
  nodeSelector,
  (state) => state.graph.providers,
  computeGraph,
  (id, providers, graph) => {
    const node = graph.nodes.find((n) => n.id === id)
    return providers.filter((p) => node.providers.includes(p.id))
  },
)
