import { api } from 'lq-ui'

export default () => api.get('/graph')
  .then((graph) => ({
    nodes: [],
    edges: [],
    providers: [],
    meetings: [],
    ...graph,
  }))
