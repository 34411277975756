const initialState = null


export default (selectedProvider = initialState, action) => {
  switch (action.type) {
    case 'SELECT_PROVIDER':
      return action.payload
    default:
      return selectedProvider
  }
}
