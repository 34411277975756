import React from 'react'
import { PrintView } from 'lq-ui'
import styles from './GraphConfigurationStyles'

const GraphPrint = () => {
  const classes = styles()
  return (
    <div className={classes.printGraph}>
      <PrintView appendNodeId="graphId" className={classes.print} />
    </div>
  )
}

export default GraphPrint
