import graph from './graph'
import maps from './maps'
import auth from './auth'
import proposals from './proposals'

export default {
  graph,
  maps,
  auth,
  proposals,
}
