import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    media: {
      verticalAlign: 'bottom',
      width: '100%',
      height: '20rem',
    },
  })

  const classes = useStyles()
  return classes
}
